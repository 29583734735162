<template>
  <div dir="ltr" class="customize__index " style="text-align:start;overflow-x:hidden;min-height:100vh;">
      <div class="row mt-3 ">
            <div class="col-12">
                <div style="" class="">
                        <button class="btn" @click="goBack"><b-icon-arrow-left class="mt-n2 mr-1"></b-icon-arrow-left> Go Back</button>
                    </div>
                <div class=" d-flex justify-content-center w-100">
                    
                    <div class="d-flex justify-content-center " >
                        <div class=" d-flex  w-100 m-0">
                           <div
                    class="d-flex align-items-center px-2 py-1 ml-n5"
                    style="border-radius: 6px; background: #ffffff"
                >
                    <button
                        @click="isDesktopValue = true"
                        :class="
                           isDesktopValue
                                ? 'toggleSelectedButton'
                                : 'toggleButton'
                        "
                        class="btn py-2"
                        style="width: 90px"
                    >
                        Desktop
                    </button>
                    <button
                        @click="isDesktopValue = false"
                        :class="
                           !isDesktopValue
                                ? 'toggleSelectedButton'
                                : 'toggleButton'
                        "
                        class="btn py-2"
                        style="width: 90px"
                    >
                        Mobile
                    </button>
                </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div class="col-12 text-center mt-5">
                <span> <span class="font-weight-bold">Note :</span> This is a testing page. No leads or engagement will be counted.</span>
            </div>
            <div class="col-12 w-100 h-100">
                <!-- <div style="min-height:60vh;" class="w-100  h-100"><iframe :src="getQuizSrc()" frameborder="0" style="width:100%;min-height:85vh;z-index:100" ></iframe> </div> -->
                <Index :isDesktopValue="isDesktopValue" QuizKey="0rRBNTyZYn0GA5ubsZHZm2"  />
            </div>
          </div>

  </div>
</template>

<script>
import { BIconArrowLeft } from "bootstrap-vue";
import Index from './MainPage.vue'
export default {
    components:{
        Index,
       
        BIconArrowLeft
    },
data(){
    return{
        isDesktopValue:true,
        ApiToken:''
    }
},
mounted(){
},
    methods:{
      goBack(){
        this.$router.go(-1)
      },
      getQuizSrc(){
      if(this.$route.params.id){
        return process.env.VUE_APP_QUIZ_PREVIEW_URL+ 'test/' + this.$route.params.id
      }
    },
    },
    computed:{
      
    }
}
</script>

<style scoped>

.toggleButton {
    background: #f6f6f6;
    color: #292929;
    font-size: 14px;
    font-weight: 400px;
}
.toggleSelectedButton {
    background: rgba(77, 27, 126, 0.8) !important;
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 500px !important;
}
.toggleSelectedButton:hover {
    color: #ffffff !important;
}

</style>
